<template>
  <Popover v-slot="{ open }: { open: boolean }" class="relative">
    <PopoverButton as="div">
      <div ref="reference" class="w-full">
        <slot
          name="button"
          :="{ open: (!static && open) || (static && staticOpen) }"
        ></slot>
      </div>
    </PopoverButton>

    <Teleport to="body">
      <div ref="floating" class="z-40" :="{ style }">
        <Transition name="popover" mode="out-in">
          <PopoverPanel
            v-if="!static || staticOpen"
            v-slot="{ close }: { close: () => void }"
            :="{ static }"
            class="pointer-events-auto mx-0.5 my-1"
          >
            <div
              class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <slot :="{ close }"></slot>
            </div>
          </PopoverPanel>
        </Transition>
      </div>
    </Teleport>
  </Popover>
</template>

<script setup lang="ts">
import { Placement } from '@floating-ui/core'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

// defines
const props = defineProps<{
  placement?: Placement
  static?: boolean
  staticOpen?: boolean
}>()

// data
const { reference, floating, style } = useFloating(
  props.placement ?? 'bottom-end'
)
</script>
